import { action, computed, makeObservable, observable } from 'mobx';
// TODO: Implement an onboarding model
import { CLOUDFLARE_VARIANTS_ENUM } from '@10x/foundation/src/utilities/getCloudflareSizeRecognition';
import type {
  ChannelMessageAttachmentPayload,
  ChannelMessagePayload,
} from '@10x/foundation/types/graphql-schema';

import { getPassedTime } from '@foundationPathAlias/utilities';

import { AttachmentModel } from './attachments/Attachment.model';
import { AttachmentsRegistry } from './attachments/types';
import { DataModel } from './Data.model';

import { injectable } from 'inversify';
import type { IChannelStore } from './Channel.store.types';
import type { ICommunityStore } from './Community.store.types';
import { optimisticMocksService } from './optimistic-mocks';
import { UserModel } from './User.model';
import type { IUserStore } from './User.store.types';

// TODO: empty mock message
const messageMock = {
  // empty lexical state
  // rawJson:
  //   '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
  // text: '',
};

@injectable()
export class MessageModel extends DataModel {
  static typename = 'ChannelMessagePayload';
  userStore: IUserStore;
  communityStore: ICommunityStore;
  channelStore: IChannelStore;
  // TODO: DI
  static optimisticMocksService = optimisticMocksService;

  serverData: ChannelMessagePayload;

  static generateMockedMessageModel(
    userStore: IUserStore,
    communityStore: ICommunityStore,
    channelStore: IChannelStore
  ) {
    return new MessageModel(
      { ...messageMock } as any,
      userStore,
      communityStore,
      channelStore
    );
  }

  attachments: AttachmentsRegistry = {};

  AttachmentModel;

  // TODO: true after the model had been added to the optimistic queue
  optimistic = false;

  editing = false;

  // TODO: true if the messaged failed to sent to BE. should render Retry btn
  failed = false;

  // to determinate if it's a reply parent
  isParentAndScrollTo = false;

  // for easiest order index manipulation in the message list.
  // TODO: I think would be nice to rework in and create some Generic wrapper for the message nodes registry (it's a double linked list in fact)
  orderIndex = 0;

  // used for the pagination in Message.store and in the ChannelMessageList
  cursor: string | null = null;

  authorModel: UserModel | null = null;
  // can be used as an idetifier if message contains a parent
  parentMessageModel: MessageModel | null = null;

  get createdAt() {
    return getPassedTime(this.serverData.createdAt);
  }

  constructor(
    data: ChannelMessagePayload,
    userStore: IUserStore,
    communityStore: ICommunityStore,
    channelStore: IChannelStore
  ) {
    super();

    this.userStore = userStore;
    this.communityStore = communityStore;
    this.channelStore = channelStore;

    this.AttachmentModel = AttachmentModel;

    this.serverData = data;

    this.authorModel = new UserModel(data.author);

    this.parentMessageModel = data.parent?.node
      ? new MessageModel(
          data.parent.node as any,
          userStore,
          communityStore,
          channelStore
        )
      : null;

    // don't need to generate ID if we have the real one
    if (data.id) {
      this.id = data.id;
    }

    makeObservable(this, {
      serverData: observable,
      attachments: observable,
      failed: observable,
      editing: observable,
      isParentAndScrollTo: observable,
      createdAt: computed,
      setFailed: action,
      setEditing: action,
      setIsParentAndScrollTo: action,
      updateFromJSON: action,
      updateAttachments: action,
      resetAttachments: action,
      setServerData: action,
      deleteAttachmentModelById: action,
    });

    this.initAttachments(data.attachments);
  }
  // theoretically for savings in storage but, basically, I think backendPayload is enough
  get asJson() {
    return {
      ...this.serverData,
      attachments: Object.values(this.attachments),
    };
  }

  // for BE integration - create community
  get backendPayload() {
    return {
      ...this.serverData,
      attachments: Object.values(this.attachments),
    };
  }

  get attachmenthsCount() {
    let count = 0;
    if (this.attachments) {
      count = Object.keys(this.attachments).length;
    }

    return count;
  }

  get isAttachmentsUploading() {
    return Object.values(this.attachments).some((o) => o.uploading);
  }

  get hasSomeAttachmentWasUploaded() {
    if (!this.attachmenthsCount) {
      return false;
    }
  }

  get hasNoText() {
    // return !this.data.plainText && !this.data.markupText;
    return false;
  }

  getFirstAttachmentIfExists = () => {
    let res = null;
    if (this.attachmenthsCount) {
      res = Object.values(this.attachments)[0];
    }
    return res;
  };

  clone = () => {
    return new MessageModel(
      { ...this.serverData },
      this.userStore,
      this.communityStore,
      this.channelStore
    );
  };

  updateFromJSON = (data: ChannelMessagePayload) => {
    this.serverData = data;
    // don't need to generate ID if we have the real one
    if (data.id) {
      this.id = data.id;
    }

    this.initAttachments(data.attachments);
  };

  setFailed = (failed: boolean) => {
    this.failed = failed;
  };
  setEditing = (editing: boolean) => {
    this.editing = editing;
  };
  setIsParentAndScrollTo = (isParentAndScrollTo: boolean) => {
    this.isParentAndScrollTo = isParentAndScrollTo;
  };

  setServerData = (data: Partial<ChannelMessagePayload>) => {
    this.serverData = { ...this.serverData, ...data };
  };

  // TODO: type
  // forClient means that this model will be used for the optimistic message mobx when attachments are loading thus the additional client only data must be added
  generateOptimisticResponse(
    data: {
      text: string;
      stringifiedEditorState: string;
      id: string;
      replyModel?: MessageModel | null;
    },
    forClient = false
  ) {
    const { text, stringifiedEditorState, id, replyModel } = data;
    const currentUser = this.userStore.me?.backendPayload;
    const optimisticResponse =
      MessageModel.optimisticMocksService.generateChannelMessagePayload({
        id,
      });
    const authorPayload =
      MessageModel.optimisticMocksService.generateUser(currentUser);

    optimisticResponse.text = text;
    optimisticResponse.rawJson = stringifiedEditorState;

    optimisticResponse.author = authorPayload;
    optimisticResponse.authorId = authorPayload.id;

    // @ts-ignore
    optimisticResponse.parentId = replyModel?.serverData.id;
    // @ts-ignore
    optimisticResponse.parent = {
      cursor: null,
      node: replyModel?.serverData,
    };

    // TODO: type
    //@ts-ignore
    optimisticResponse.communityId =
      this.communityStore.activeCommunity.data?.serverData.id;
    //@ts-ignore
    optimisticResponse.channelId =
      this.channelStore.activeChannel.data?.serverData.id;

    //@ts-ignore
    optimisticResponse.attachments =
      this.generateAttachmentsOptimisticResponse(forClient);

    return optimisticResponse;
  }

  generateAttachmentsOptimisticResponse(forClient = false) {
    return Object.values(this.attachments).map((attachmentModel) => {
      return attachmentModel.generateOptimisticResponse(forClient);
    });
  }

  initAttachments = (attachments: ChannelMessageAttachmentPayload[]) => {
    if (!attachments?.length) return;
    const attachmentModels = attachments.reduce((acc, o) => {
      const attachmentModel = new this.AttachmentModel(
        // TODO: change to options oboject
        undefined,
        undefined,
        this,
        o
      );

      acc[o.id] = attachmentModel;

      return acc;
    }, {} as any);

    this.updateAttachments(attachmentModels);
  };

  getAttachmentsServerIds = () => {
    const { attachments } = this;
    let res = null;
    if (!attachments) {
      return res;
    }

    const keys = Object.keys(attachments);
    if (keys.length) {
      res = keys.map((key) => attachments[key].serverAttachmentId);
    }

    return res;
  };

  //@ts-ignore
  updateAttachments = (attachments: { [index: string]: AttachmentModel }) => {
    let attachmentRegistry;

    if (Object.keys(this.attachments).length) {
      /**
       * in the case of optimistic model. Will be create the optimistic message model and the optimistic attachments model. Their parent ID will be linked to the optimistic message model, hovewer in the File Upload service there will be old attachments models from the real message model and their parentMessageId will be pointed to the real message model.
       *
       * When the file upload service will provide the attachments update
       * the attachmentsStore will see if there is running optimistic query and if yes it will PROVIDE the optimistic message model istead of the real one so the .updateAttachments will happen on the Optimisticc message model but the attachments will contain the Real Message parent ID. So need to replace them to the optimistic message model ID value.
       *
       * This flow is a bit weird but for now I haven't found anything better
       *
       * TODO: think how to refactor it
       */

      attachmentRegistry = Object.keys(attachments).reduce((acc, id) => {
        const existingAttachmentModel = this.attachments[id];
        const incomingAttachmentModel = attachments[id];

        let res;

        if (!existingAttachmentModel) {
          res = incomingAttachmentModel;
        } else {
          if (existingAttachmentModel !== incomingAttachmentModel) {
            // looks like the real attachment model is coming to the optimistic model. Should just update the existing ones

            const incomingJsonData = incomingAttachmentModel.toJson();
            existingAttachmentModel.updateFromJson(incomingJsonData);

            res = existingAttachmentModel;
          } else {
            // they are the same so just the replace the existing one
            res = incomingAttachmentModel;
          }
        }

        acc[id] = res;
        return acc;
      }, {} as AttachmentsRegistry);
    } else {
      // this.attachments is empty so can just assign the new ones
      attachmentRegistry = attachments;
    }

    this.attachments = attachmentRegistry;
  };

  getAttachmentModelById = (id: string) => this.attachments[id];

  resetAttachments = () => {
    this.attachments = {};
  };

  deleteAttachmentModelById = (id: string) => {
    delete this.attachments[id];
  };

  getAuthorAvatar(prefferedSize?: CLOUDFLARE_VARIANTS_ENUM) {
    return this.getCloudflareUrl(
      this.serverData?.author?.imageUrls as string[],
      prefferedSize
    );
  }

  failAttachmentsAfterTheFailedOne = (failedAttachmentId: string) => {
    let isFoundFailed = false;
    Object.values(this.attachments).forEach(
      (attachmentModel: AttachmentModel) => {
        if (attachmentModel.id === failedAttachmentId || isFoundFailed) {
          attachmentModel.setFailed(true);
          attachmentModel.setUploading(false);

          isFoundFailed = true;
        }
      }
    );

    this.setFailed(true);
  };

  makeUploadingAllFailedAttachments = () => {
    let isFoundFailed = false;
    const failedAttachmentsRegistry: AttachmentsRegistry = {};
    let failedItemsCounter = 0;
    let firstFailedAttachment: AttachmentModel;

    Object.values(this.attachments).forEach(
      (attachmentModel: AttachmentModel) => {
        if (attachmentModel.failed || isFoundFailed) {
          attachmentModel.setFailed(false);
          attachmentModel.setUploading(true);

          failedAttachmentsRegistry[attachmentModel.id] = attachmentModel;
          failedItemsCounter++;

          if (!isFoundFailed) {
            firstFailedAttachment = attachmentModel;
            isFoundFailed = true;
          }
        }
      }
    );

    this.setFailed(false);

    return {
      failedAttachmentsRegistry,
      failedItemsCounter,
      // @ts-ignore
      firstFailedAttachment,
    };
  };
}
