export type BottomSheetActions = {
  open: (() => void) | null;
  close: ((_velocity?: number) => void) | null;
};

export type ContentTypesRegistry = {
  auth: boolean;
  onboarding: boolean;
};

export enum InteractiveContentTypesEnum {
  AUTH = 'auth',
  ONBOARDING = 'onboarding',
  OTHER = 'other',
}

/**
 * Respons for the global modal/bottom sheet rendering depends
 * on the viewport size
 */
export interface IInteractiveStore {
  isBottomSheetOpened: boolean;
  bottomSheetCn?: string;
  isModalOpened: boolean;
  // bottomsheet or modal
  isOpened: boolean;
  // used to disable the touch move event on the bottom sheet
  disableBottomSheetTouchMove: boolean;
  content: React.ReactNode;
  /**
   * used to determinate what kind of content should be rendered
   * onboarding or auth
   */
  activeContentTypeRegistry: ContentTypesRegistry;
  // from the react-spring animations
  bottomSheetActions: BottomSheetActions;
  setActiveContentType(
    type: InteractiveContentTypesEnum,
    active: boolean
  ): void;
  resetContent(): void;
  // set it to disable the touch move event on the bottom sheet
  setDisableBottomSheetTouchMove: (isDisable: boolean) => void;
  // set the content for rendering in the interactive component
  setContent(content: React.ReactNode): void;
  // depends on the size opens/closes modal or bottomsheet
  setInteractiveElementOpen(open: boolean): void;
  // opens/closes just modal and reset's the content
  setModal: (open: boolean) => void;
  setBottomSheetCn: (cn?: string) => void;
  setIsModalOpened: (open: boolean) => void;
  setIsBottomSheetOpened: (open: boolean) => void;
  // registers native actions from react-spring
  setBottomSheetActions: (actions: BottomSheetActions) => void;
  // opens/closes bottomsheet
  setBottomSheet: (open: boolean) => void;
}
