import { useTranslation } from 'react-i18next';

import {
  OutlineButton,
  PrimaryButtonWithLoader,
} from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

type ActionButtonsProps = {
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
  submitLabel: string;
  isMobile?: boolean;
};

export const ActionButtons = (props: ActionButtonsProps) => {
  const { onClose, onSubmit, submitLabel, isSubmitting, isMobile } = props;
  const { t } = useTranslation(['common']);

  return (
    <div className="flex justify-end gap-4 border-t border-t-element-subtle p-6 dark:border-t-element-subtle-dark">
      {!isMobile && (
        <OutlineButton disabled={isSubmitting} cn="w-auto" onClick={onClose}>
          {t('cancel')}
        </OutlineButton>
      )}

      <PrimaryButtonWithLoader
        loading={Boolean(isSubmitting)}
        disabled={isSubmitting}
        cn={classNames(isMobile ? 'w-full' : 'w-auto')}
        onClick={onSubmit}
      >
        {submitLabel}
      </PrimaryButtonWithLoader>
    </div>
  );
};
