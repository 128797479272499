import { classNames } from '@foundationPathAlias/utilities';
import React from 'react';

type MenuItemProps = {
  onClick: () => void;
  label: string;
  className?: string;
  icon?: React.ReactNode;
};

export const MenuItem = (props: MenuItemProps) => {
  const { onClick, label, className, icon } = props;
  return (
    <li
      role="button"
      onClick={onClick}
      className={classNames(
        'flex h-[44px] cursor-pointer items-center px-[16px]  py-[12px] text-body16R text-text-primary hover:bg-hovered-selected',
        ' dark:hover:bg-hovered-selected-dark',
        className
      )}
    >
      <span className="mr-[16px] h-[20px] w-[20px] min-w-[20px]">{icon}</span>
      {label}
    </li>
  );
};
