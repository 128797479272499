import { Axios } from 'axios';

import { Client } from 'urql';

import {
  IAuthStore,
  IChannelStore,
  ICommunityStore,
  ICreateChannelOrGroupStore,
  ICreateCommunityStepperStore,
  IDimensionsStore,
  IInteractiveStore,
  IMessageStore,
  IPlatformStore,
  IRulesStore,
  ISystemStore,
  IToastStore,
  IUserStore,
} from '@mainApp/src/stores';

import {
  IAttachmentsRepository,
  IAuthRepository,
  IChannelRepository,
  ICommunityRepository,
  IMessageRepository,
  IOnboardingRepositoryInterface,
  IPlatformRepository,
  IUserRepository,
} from '@mainApp/src/repositories';

import { IAttachmentsMediator } from '@mainApp/src/stores/attachments/AttachmentsMediator';
import { IAttachmentsProgressBarViewStore } from '@mainApp/src/stores/attachments/AttachmentsProgressBar.view.store';
import { IFileUploadService } from '@mainApp/src/stores/attachments/FileUpload.service';

import { MessageModel } from '@mainApp/src/stores/Message.model';
import { AttachmentModel } from '@mainApp/src/stores/attachments/Attachment.model';

import { OptimisticQueueService } from '@mainApp/src/stores/attachments/OptimisticQueue.service';
import { RetryService } from '@mainApp/src/stores/attachments/Retry.service';

import { IEventBus } from '@foundationPathAlias/utilities';
import { IPostAuthOnboardingStore } from '@mainApp/src/components/post-auth-onboarding/PostAuthOnboarding.store.types';
import { GlobalEventsType } from '@mainApp/src/events';
import { DoubleLinkedRegistryQueue } from '@mainApp/src/stores/attachments/DoubleLinkedRegistryQueue';

import { IAccountSettingsStore } from '@mainApp/src/modules/account-settings/data/AccountSettings.store.types';
import { ICAPStore } from '@mainApp/src/modules/customer-admin-panel/data/types';

import type { IChannelGroupSettingsStore } from '@mainApp/src/modules/channel/channel-group-settings';
import type { IChannelSettingsStore } from '@mainApp/src/modules/channel/channel-settings/data';

import type { IReactionsRegistryService } from '@mainApp/src/stores/data-objects/ReactionsRegistry.service';

import { IRedirectService, IStorageService } from '@mainApp/src/services';

import { IProfileStore } from '@mainApp/src/modules/profile/data/types';
import { IPermissionsServiceStore } from '@mainApp/src/stores/permissions';

export type IOC_TOKEN_TYPE = {
  http: Axios;
  graphqlClient: Client;
  eventBusFactory: any;
  eventBus: IEventBus<GlobalEventsType>;

  // stores

  systemStore: ISystemStore;
  dimensionsStore: IDimensionsStore;
  toastStore: IToastStore;
  authStore: IAuthStore;
  postAuthOnboardingStore: IPostAuthOnboardingStore;
  createCommunityStepperStore: ICreateCommunityStepperStore;
  communityStore: ICommunityStore;
  channelStore: IChannelStore;
  userStore: IUserStore;
  interactiveStore: IInteractiveStore;
  messageStore: IMessageStore;
  capStore: ICAPStore;
  platformStore: IPlatformStore;
  rulesStore: IRulesStore;
  accountSettingsStore: IAccountSettingsStore;
  createChannelOrGroupStore: ICreateChannelOrGroupStore;
  channelSettingsStore: IChannelSettingsStore;
  channelGroupSettingsStore: IChannelGroupSettingsStore;
  profileStore: IProfileStore;

  attachmentsProgressBarViewStore: IAttachmentsProgressBarViewStore;

  // repositories
  authRepository: IAuthRepository;
  onboardingRepository: IOnboardingRepositoryInterface;
  communityRepository: ICommunityRepository;
  channelRepository: IChannelRepository;
  userRepository: IUserRepository;
  messageRepository: IMessageRepository;
  platformRepository: IPlatformRepository;

  attachmentsMediator: IAttachmentsMediator;

  fileUploadService: IFileUploadService;
  retryService: RetryService;
  optimisticQueueService: OptimisticQueueService<any>;
  redirectService: IRedirectService;
  storageService: IStorageService;

  permissionsServiceStore: IPermissionsServiceStore;
  reactiosnRegistryService: IReactionsRegistryService;

  attachmentsRepository: IAttachmentsRepository;

  // models that will be be injected as Constructor
  MessageModel: MessageModel;
  AttachmentModel: AttachmentModel;
  DoubleLinkedRegistryQueue: DoubleLinkedRegistryQueue;
};

const IOC_TOKENS: { [K in keyof IOC_TOKEN_TYPE]: K } = {
  http: 'http',
  graphqlClient: 'graphqlClient',
  eventBus: 'eventBus',

  systemStore: 'systemStore',
  dimensionsStore: 'dimensionsStore',
  toastStore: 'toastStore',
  authStore: 'authStore',

  postAuthOnboardingStore: 'postAuthOnboardingStore',
  createCommunityStepperStore: 'createCommunityStepperStore',
  communityStore: 'communityStore',
  channelStore: 'channelStore',
  interactiveStore: 'interactiveStore',
  userStore: 'userStore',
  messageStore: 'messageStore',
  capStore: 'capStore',
  platformStore: 'platformStore',
  rulesStore: 'rulesStore',
  accountSettingsStore: 'accountSettingsStore',
  createChannelOrGroupStore: 'createChannelOrGroupStore',
  channelSettingsStore: 'channelSettingsStore',
  channelGroupSettingsStore: 'channelGroupSettingsStore',
  profileStore: 'profileStore',

  authRepository: 'authRepository',
  onboardingRepository: 'onboardingRepository',
  communityRepository: 'communityRepository',
  channelRepository: 'channelRepository',
  userRepository: 'userRepository',
  messageRepository: 'messageRepository',
  platformRepository: 'platformRepository',

  eventBusFactory: 'eventBusFactory',
  attachmentsMediator: 'attachmentsMediator',

  attachmentsProgressBarViewStore: 'attachmentsProgressBarViewStore',

  fileUploadService: 'fileUploadService',
  optimisticQueueService: 'optimisticQueueService',
  retryService: 'retryService',
  redirectService: 'redirectService',
  storageService: 'storageService',

  permissionsServiceStore: 'permissionsServiceStore',
  reactiosnRegistryService: 'reactiosnRegistryService',

  attachmentsRepository: 'attachmentsRepository',

  MessageModel: 'MessageModel',
  AttachmentModel: 'AttachmentModel',
  DoubleLinkedRegistryQueue: 'DoubleLinkedRegistryQueue',
};

export default IOC_TOKENS;
