import 'reflect-metadata';

import type { AppProps } from 'next/app';

import { urqlClient } from '@mainApp/src/config/urql.config';
import { IOC_TOKENS, iocContainer, IOCProvider } from '@mainApp/src/ioc';
import '@mainApp/src/styles/common.css';
import '@mainApp/src/styles/editor.css';
import cookie from 'js-cookie';
import { Provider } from 'urql';

import { appWithI18Next, useSyncLanguage } from 'ni18n';

import { ni18nConfig } from '../ni18n.config';

// ------ Import Swiper styles
import '@mainApp/src/components/forked-thirdparty/reactjs-popup/src/index.css';
import 'reactjs-popup/dist/index.css';

import 'swiper/css';
import 'swiper/css/pagination';

// ------ !Import Swiper styles

import { StorageDataKeysEnum } from '@mainApp/src/services';
import type { ISystemStore } from '@mainApp/src/stores';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppWrapper from './AppWrapper';
// TODO: remove any for the pageProps.messages that comes from the nextIntl
function App({ Component, pageProps }: AppProps & any) {
  const locale =
    typeof window !== 'undefined' &&
    cookie.get(StorageDataKeysEnum.USER_LOCALE);

  const { i18n } = useTranslation();

  const systemStore: ISystemStore = iocContainer.get(IOC_TOKENS.systemStore);
  useEffect(() => {
    if (i18n && systemStore) {
      systemStore.setI18n(i18n);
    }
  }, [i18n, systemStore]);

  // localStorage could return null but the useSyncLanguage hook requires eiter string or undefined
  useSyncLanguage(locale || 'en');

  // !!! ATTTENTION. Restored onboarding Community automatically creates in the Root.store->initOnboardingStore()
  return (
    <div id="root" className="themed-text bg-baseTheme-background-product">
      <Provider value={urqlClient}>
        <IOCProvider container={iocContainer}>
          <AppWrapper Component={Component} pageProps={pageProps} />
        </IOCProvider>
      </Provider>
    </div>
  );
}

export default appWithI18Next(App, ni18nConfig);
