import { colorSchema } from '@rootConfig/color-schema';

export const AvatarSVG: React.FC<{
  width?: number;
  height?: number;
  darkMode?: boolean;
  className?: string;
}> = ({ width = 24, height = 26, darkMode, ...otherProps }) => {
  let secondaryColor = colorSchema['background-secondary'];
  let mainColor = colorSchema['primary-100'];

  if (darkMode) {
    secondaryColor = colorSchema['background-secondary-dark'];
    mainColor = colorSchema['primary-100-dark'];
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 26`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
        fill={secondaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9615 15.0481C14.9449 15.0481 17.3634 12.6296 17.3634 9.64621C17.3634 6.6628 14.9449 4.24428 11.9615 4.24428C8.9781 4.24428 6.55957 6.6628 6.55957 9.64621C6.55957 12.6296 8.9781 15.0481 11.9615 15.0481ZM8.32768 17C6.1084 17 4.27607 18.7333 4 20.9765C6.11789 22.8569 8.90587 23.9991 11.9606 24H11.964H11.9675C15.0222 23.9991 17.8102 22.8569 19.9281 20.9765C19.652 18.7333 17.8197 17 15.6004 17H8.32768Z"
        fill={mainColor}
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.771704 12C0.771704 18.2012 5.79878 23.2283 12 23.2283C18.2012 23.2283 23.2283 18.2012 23.2283 12C23.2283 5.79878 18.2012 0.771704 12 0.771704C5.79878 0.771704 0.771704 5.79878 0.771704 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0Z"
        fill={secondaryColor}
      />
    </svg>
  );
};
