import {
  ContentRulePayload,
  PlatformContentRulePayload,
} from '@10x/foundation/types';
import type { ICommunityRepository } from '@mainApp/src/repositories';
import { inject, injectable } from 'inversify';
import { debounce } from 'lodash';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { IOC_TOKENS } from '../ioc';
import { ApiBase } from './ApiBase';
import type { ICommunityStore } from './Community.store.types';
import type { IToastStore } from './Toast.store';

const DEBOUNCE_TIMEOUT_TO_PERSIST_SORT = 1500;

@injectable()
export class RulesStore extends ApiBase implements IRulesStore {
  repository: ICommunityRepository;
  parentRuleDetails: IPlatformRule | null = null;

  // one this page is initialized the rules are loaded initially
  loadingRules = true;
  communityRules: ICommunityRule[] = [];
  communityStore: ICommunityStore;

  get activeCommunityId() {
    const id = this.communityStore.activeCommunity.data?.serverData?.id;

    return id || '';
  }

  constructor(
    @inject(IOC_TOKENS.communityRepository)
    repository: ICommunityRepository,
    @inject(IOC_TOKENS.communityStore) communityStore: ICommunityStore,
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore
  ) {
    super(toastStore);
    this.repository = repository;
    this.communityStore = communityStore;

    makeObservable(this, {
      communityRules: observable,
      loadingRules: observable,
      communityStore: observable,
      parentRuleDetails: observable,
      activeCommunityId: computed,
      deleteRule: action,
      loadCommunityRules: action,
      persistOrder: action,
      reset: action,
      editRule: action,
      createRule: action,
    });

    this.debouncedPersistOrder = debounce(
      this.debouncedPersistOrder.bind(this),
      DEBOUNCE_TIMEOUT_TO_PERSIST_SORT
    ) as () => Promise<void>;
  }

  debouncedPersistOrder = async () => {
    try {
      const originalCommunityRules = [...this.communityRules];
      const rulesId = this.communityRules.map((rule) => rule.id);

      const { error } = await this.repository.updateCommunityRulesOrder(
        this.activeCommunityId,
        rulesId
      );

      if (error) {
        runInAction(() => {
          this.communityRules = originalCommunityRules;
        });

        throw error;
      }
    } catch (error: any) {
      this.handleError(
        'Error on persist the community rules order',
        error.message
      );
    }
  };

  persistOrder = async () => {
    this.debouncedPersistOrder();
  };

  editRule = async ({ rawJSON, id, title }: RuleEdit) => {
    if (!this.activeCommunityId) {
      throw Error('Community not found');
    }

    const { error } = await this.repository.editCommunityRule(
      this.activeCommunityId,
      id,
      {
        title,
        rawJSON,
      }
    );

    if (error) {
      this.handleError('Error', error.message);
      throw Error(error.message);
    }

    runInAction(async () => {
      await this.loadCommunityRules();
    });
  };

  createRule = async ({ rawJSON, title }: RuleCreate) => {
    if (!this.activeCommunityId) {
      throw Error('Community not found');
    }

    const { data: rule, error } = await this.repository.createCommunityRule(
      this.activeCommunityId,
      {
        title,
        rawJSON,
      }
    );

    if (error) {
      this.handleError('Error', error.message);
      throw Error(error.message);
    }

    if (rule) {
      runInAction(() => {
        this.communityRules = [
          ...this.communityRules,
          { ...rule, baseType: 'community' } as ICommunityRule,
        ].sort((a, b) => a.order - b.order);
      });
    }
  };

  deleteRule = async (ruleId: string) => {
    if (!this.communityRules) return;

    const originalList = [...this.communityRules];

    runInAction(() => {
      this.communityRules = [
        ...this.communityRules.filter((r) => r.id !== ruleId),
      ];
    });

    const res = await this.repository.deleteCommunityRule(
      this.activeCommunityId,
      ruleId
    );

    if (res.error) {
      runInAction(() => {
        this.communityRules = originalList;
      });
      throw new Error(res.error.message);
    }
  };

  // Sort rules section - Begin
  moveRule = (dragIndex: number, hoverIndex: number) => {
    if (!this.communityRules) {
      return;
    }

    const dragCard = this.communityRules[dragIndex];
    this.communityRules.splice(dragIndex, 1);
    this.communityRules.splice(hoverIndex, 0, dragCard);

    // TODO: make a flow to persist on BE
    this.persistOrder();
  };
  // Sort rules section - End

  loadCommunityRules = async () => {
    runInAction(() => {
      this.loadingRules = true;
    });
    const res = await this.repository.getCommunityRules(
      this.activeCommunityId,
      { includeInactive: true }
    );

    runInAction(() => {
      this.communityRules = res.data
        ? res.data.map((rule) => ({
            ...rule,
            baseType: 'community',
          }))
        : [];

      this.loadingRules = false;
    });
  };

  goBackToParentRule = () => {
    if (this.parentRuleDetails) {
      this.parentRuleDetails = null;
    }
  };

  reset = () => {
    this.communityRules = [];
    this.parentRuleDetails = null;
  };
}

type RuleType = 'community' | 'platform';

export type IBaseRule = Pick<
  ContentRulePayload,
  'id' | 'title' | 'rawJSON' | 'order'
> & {
  baseType?: RuleType;
};

type RuleCreate = {
  title: string;
  rawJSON: string;
};

type RuleEdit = RuleCreate & {
  id: string;
};

export interface ICommunityRule extends IBaseRule {
  communityId?: ContentRulePayload['communityId'];
  baseType?: 'community';
}

export interface IPlatformRule extends IBaseRule {
  childrens?: PlatformContentRulePayload['childrens'];
  type?: PlatformContentRulePayload['type'];
  baseType?: 'platform';
}

export interface IRulesStore {
  readonly parentRuleDetails: IPlatformRule | null;
  readonly loadingRules: boolean;
  readonly communityRules: ICommunityRule[];
  readonly activeCommunityId: string;
  goBackToParentRule: () => void;

  deleteRule: (ruleId: string) => Promise<void>;
  editRule: (data: RuleEdit) => Promise<void>;
  createRule: (data: RuleCreate) => Promise<void>;

  moveRule: (dragIndex: number, hoverIndex: number) => void;

  loadCommunityRules: () => Promise<void>;

  reset: () => void;
}
