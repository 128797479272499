/**
 * This store contains the client system data like: dark/light mode, etc.
 */

import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import type { IInteractiveStore, ISystemStore } from './types';

import { inject, injectable } from 'inversify';

import { getOSDmData } from '@10x/foundation/src/utilities';

import { IOC_TOKENS } from '../ioc';

import { LocaleEnum, ThemeEnum, localesData } from '@mainApp/src/config';
import { i18n } from 'i18next';
import type { IStorageService } from '../services';
import { StorageDataKeysEnum } from '../services';

enableStaticRendering(typeof window === 'undefined');

@injectable()
export class SystemStore implements ISystemStore {
  private _cachedOSThemeSubscriptionQuery: MediaQueryList | null = null;
  isDarkTheme = false;
  // a hamburger menu that slides down on mobile
  isMobileNavOpened = false;
  // TODO: Why it's inside the system store if it's a message specific thing?
  areReactionsOpen = false;
  i18n: null | i18n = null;

  isSidebarOpened = false;
  locale = LocaleEnum.EN;

  get localeLabel() {
    return localesData[this.locale].label;
  }

  private storageService: IStorageService;

  private documentEl: HTMLElement | null = null;

  constructor(
    @inject(IOC_TOKENS.interactiveStore) interactiveStore: IInteractiveStore,
    @inject(IOC_TOKENS.storageService) storageService: IStorageService
  ) {
    this.storageService = storageService;
    const cookieUserTheme = storageService.getCookie(StorageDataKeysEnum.THEME);
    this.initDocument();

    makeObservable(this, {
      isDarkTheme: observable,
      isMobileNavOpened: observable,
      areReactionsOpen: observable,
      isSidebarOpened: observable,
      locale: observable,
      localeLabel: computed,
      setDarkTheme: action,
      setLocale: action,
      setMobileNavOpen: action,
      setReactionsOpen: action,
      setIsSidebarOpened: action,
    });
    this.setDarkTheme(cookieUserTheme === ThemeEnum.DM);

    // TODO: this store is permanent so there is no need to use disposer
    reaction(
      () => {
        return interactiveStore.isBottomSheetOpened && this.isMobileNavOpened;
      },
      (shouldCloseMobileNav) => {
        // close the navbar mobile menu automatically on opened bsheet
        if (shouldCloseMobileNav) {
          this.setMobileNavOpen(false);
        }
      }
    );
  }

  setI18n = (i18n: i18n) => {
    if (!i18n)
      throw new Error(`i18n instance must be passed. Received: ${i18n}`);
    this.i18n = i18n;
  };

  setLocale = (locale: LocaleEnum) => {
    if (this.locale === locale) return;
    this.locale = locale;
  };

  setDarkTheme = (isDarkTheme: boolean) => {
    this.isDarkTheme = isDarkTheme;
    this.setDarkModeClassOnDocumentEl(isDarkTheme);

    this.storageService.setCookie(
      StorageDataKeysEnum.THEME,
      isDarkTheme ? ThemeEnum.DM : ThemeEnum.LM,
      {
        secure: true,
        sameSite: 'none',
      }
    );
  };

  private initDocument() {
    this.documentEl = global?.window?.document.documentElement;
  }

  private setDarkModeClassOnDocumentEl = (isDarkTheme: boolean) => {
    const action = isDarkTheme ? 'add' : 'remove';
    this.documentEl?.classList[action](ThemeEnum.DM);
  };

  setBodyOverflow = (isBodyOverflow: boolean) => {
    const action = isBodyOverflow ? 'add' : 'remove';
    this.documentEl?.classList[action]('overflow-hidden');
  };

  setMobileNavOpen = (open: boolean) => {
    this.isMobileNavOpened = open;
    // set overflow hidden when opened mobile nav to prevent scroll
    this.setBodyOverflow(open);
  };

  setReactionsOpen = (open: boolean) => {
    this.areReactionsOpen = open;
  };

  setIsSidebarOpened = (open: boolean) => {
    this.isSidebarOpened = open;
  };

  subscribeToOSDarkMode = () => {
    const OSDmThemeData = getOSDmData();
    if (!OSDmThemeData) {
      // it shouldn't happen but anyway just in cose of old browser or something
      console.info('OS Theme does not supported by the browser');
      return;
    }

    const { isDark, query } = OSDmThemeData;
    this.setDarkTheme(isDark);

    this.unsubscribeFromOSDarkMode();

    this._cachedOSThemeSubscriptionQuery = query;
    query.addEventListener('change', this.osDmChangeHandler);
  };

  unsubscribeFromOSDarkMode = () => {
    if (this._cachedOSThemeSubscriptionQuery) {
      this._cachedOSThemeSubscriptionQuery.removeEventListener(
        'change',
        this.osDmChangeHandler
      );
    }
  };

  private osDmChangeHandler = (e: MediaQueryListEvent) => {
    if (e.matches) {
      this.setDarkTheme(true);
    } else {
      this.setDarkTheme(false);
    }
  };
}
