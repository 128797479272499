// based on: https://github.com/balloob/react-sidebar#readme
// @ts-ignore
import { useLayoutEffect } from 'react';
// @ts-ignore
import ReactSidebar from 'react-sidebar';

export const SIDEBAR_CONTENT_ID = 'sidebar-content';

export type SidebarPropsType = {
  open: boolean;
  onSetOpen: () => void;
  onContentScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  children: React.ReactNode | React.ReactNode[];
  sidebarContent: React.ReactNode | React.ReactNode[];
  docked?: boolean;
  /**
   * Width in pixels you can start dragging from the edge when the sidebar is closed. */
  touchHandleWidth?: number;
  /**
   * Distance the sidebar has to be dragged before it will open/close after it is released.
   */
  dragToggleDistance?: number;
  contentClassName?: string;

  styles?: {
    root?: any;
    sidebar?: any;
    content?: any;
    overlay?: any;
    dragHandle?: any;
  };
};

export function Sidebar(props: SidebarPropsType) {
  const {
    children,
    sidebarContent,
    onContentScroll,
    styles = {},
    ...otherProps
  } = props;

  useLayoutEffect(() => {
    const sidebarContentEl = document.getElementById(SIDEBAR_CONTENT_ID);

    if (!sidebarContentEl || !onContentScroll) return;

    const scrollListener = (e: Event) => {
      onContentScroll?.(e as unknown as React.UIEvent<HTMLDivElement>);
    };
    sidebarContentEl.addEventListener('scroll', scrollListener);

    return () => {
      sidebarContentEl.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <ReactSidebar
      sidebar={sidebarContent}
      shadow={false}
      contentId={SIDEBAR_CONTENT_ID}
      styles={{
        ...styles,
        sidebar: {
          backgroundColor: 'white',
          ...(styles.sidebar || {}),
        },
      }}
      {...otherProps}
    >
      {children}
    </ReactSidebar>
  );
}
