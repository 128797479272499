import _debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Cropper,
  CropperOrientationEnum,
  PrimaryButtonWithLoader,
} from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

import { systemConfig } from '@mainApp/src/config';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { ActionButtons } from './ActionButtons';
import { CropperHeaderModal } from './CropperHeaderModal';

type UserCropImageProps = {
  file: File;
  onCropDone: () => void;
  onBackOrCancel: () => void;
};

const _UserCropImage = (props: UserCropImageProps) => {
  const { file, onCropDone, onBackOrCancel } = props;
  const {
    dimensionsStore: { isMobile },
    userStore,
  } = useMultipleInjection([
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.userStore,
  ]);
  const { t } = useTranslation(['channel']);
  const [croppedFile, setCroppedFile] = React.useState(file);

  const src = React.useMemo(() => {
    return URL.createObjectURL(file);
  }, [file]);

  const debouncedOnCrop = React.useRef(
    _debounce((cropper) => {
      cropper.getCanvas().toBlob((blob: any) => {
        setCroppedFile(
          new File([blob], file.name, {
            type: blob.type,
          })
        );
      });
    }, systemConfig.debounceDelay)
  ).current;

  return (
    <div
      className={classNames(
        'bg-background-primary dark:bg-background-primary-dark',
        isMobile ? 'flex h-screen w-full flex-col' : 'w-[480px]'
      )}
    >
      <CropperHeaderModal
        label={t('editProfileModal.cropImage')}
        buttonType="chevron"
        onCancel={onBackOrCancel}
      />

      <div className={classNames(!isMobile && 'p-6')}>
        {src && (
          <div className="flex flex-1 justify-center">
            <Cropper
              src={src}
              onUpdate={debouncedOnCrop}
              wrapperClassName={isMobile ? '' : 'h-[434px]'}
              stencilSize={{
                width: 311,
                height: 311,
              }}
              cropBoxOrientation={CropperOrientationEnum.Circle}
              renderRangeSlider={(rangeSlider) => {
                return (
                  <div className="px-4">
                    <p className="mb-[16px] mt-[24px] text-center text-body16SB text-text-primary dark:text-text-primary-dark">
                      {t('editProfileModal.dragToFitImage')}
                    </p>
                    {rangeSlider}
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>

      {isMobile ? (
        <div className="mt-auto flex justify-center py-7">
          <PrimaryButtonWithLoader
            cn="w-auto rounded-full"
            loading={userStore.isUploading}
            disabled={userStore.isUploading}
            onClick={() => {
              userStore.updateAvatarUser(croppedFile).then(onCropDone);
            }}
          >
            {t('editProfileModal.upload')}
          </PrimaryButtonWithLoader>
        </div>
      ) : (
        <ActionButtons
          onClose={onBackOrCancel}
          isSubmitting={userStore.isUploading}
          onSubmit={() => {
            userStore.updateAvatarUser(croppedFile).then(onCropDone);
          }}
          submitLabel={t('editProfileModal.upload')}
        />
      )}
    </div>
  );
};

export const UserCropImage = observer(_UserCropImage);
