import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from '@foundationPathAlias/components';
import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenIds } from '@mainApp/src/modules/profile/data/constants';

import { ActionButtons } from './ActionButtons';
import { ScreenWrapper } from './ScreenWrapper';

export function _Name() {
  const { userStore, profileStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.profileStore,
    IOC_TOKENS.userStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { t } = useTranslation(['channel'], {
    keyPrefix: 'editProfileModal',
  });

  const userData = userStore?.me?.serverData;

  const isDirty = userData?.displayName !== userStore?.me?.displayName;

  const resetAction = useCallback(() => {
    userStore.setDataField('displayName', userData?.displayName as string);
  }, []);

  useEffect(() => {
    const dirtyData = isDirty
      ? {
          initialVal: userData?.displayName || '',
          currentVal: userStore?.me?.displayName || '',
          screenName: ScreenIds.NAME,
          resetAction,
        }
      : null;

    profileStore.setDirtyScreenData(dirtyData);
  }, [isDirty]);

  return (
    <ScreenWrapper title={t<string>('editName')} showBackBtn={true}>
      <div className="flex-1 px-[12px] py-[24px]">
        <label
          htmlFor="name"
          className="themed-text mb-[24px] block text-left text-body16R"
        >
          {t('addNameOrNickname')}
        </label>
        <TextInput
          onChange={(val) => {
            userStore.setDataField('displayName', val);
          }}
          placeholder={t('addNameOrNickname') as string}
          InputProps={{
            id: 'name',
            defaultValue: userData?.displayName || undefined,
          }}
        />
      </div>
      {isDirty && (
        <ActionButtons
          isMobile={dimensionsStore.isMobile}
          onClose={profileStore.tryMoveBack}
          onSubmit={userStore.updateCurrentUser}
          isSubmitting={userStore.isSubmitting}
          submitLabel={t('saveChanges')}
        />
      )}
    </ScreenWrapper>
  );
}

export const Name = withAnimatedStack(observer(_Name));
