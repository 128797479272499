import { User } from '@10x/foundation/types';
import {
  Avatar,
  CommunitySVG,
  DotsButton,
  IconButton,
  OutlineButton,
} from '@foundationPathAlias/components';
import { TooltipMenu } from '@foundationPathAlias/components/menu/tooltip-menu/TooltipMenu';
import { classNames } from '@foundationPathAlias/utilities';
import {
  ArrowUturnRightIcon,
  Cog6ToothIcon,
  FlagIcon,
  LinkIcon,
  NoSymbolIcon,
} from '@heroicons/react/20/solid';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import { colorSchema } from '@rootConfig/color-schema';

type UserOptionClick = 'share' | 'copy-profile-link' | 'report' | 'block';

export type UserDataProfile = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'username' | 'bio' | 'website' | 'imageUrls'
> & {
  avatarUrl?: string;
};

type UserProfileCardProps = {
  ownProfile?: boolean;
  rootCn?: string;
  onCtaClick?: () => void;
  onSettings?: () => void;
  onClickOption?: (option: UserOptionClick) => void;
  user?: UserDataProfile | null;
  onClose: () => void;
  darkMode?: boolean;
  /** true in case of message user profile popover show because there shouldn't be the edit profile button */
  hideAction?: boolean;
  /** true in case of message user profile popover show because there shouldn't be the menu right now */
  hideMenu?: boolean;
};

const _UserProfileCard = (props: UserProfileCardProps) => {
  const {
    ownProfile,
    rootCn,
    onCtaClick,
    user,
    onSettings,
    onClose,
    darkMode,
    hideAction,
    hideMenu,
    onClickOption,
  } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const menuContentRef = React.useRef<HTMLUListElement>(null);

  const {
    dimensionsStore: { isMobile },
    interactiveStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.interactiveStore,
  ]);

  const { t } = useTranslation(['common']);

  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const { firstName, lastName, username, bio, website, avatarUrl } = user || {};

  const displayName = [firstName, lastName].filter(Boolean).join(' ');

  let userProfileContent: any = null;

  const handleClickOption = (option: UserOptionClick) => () => {
    if (isMobile) {
      onClickOption?.(option);
      interactiveStore.setContent(userProfileContent);
      return;
    }

    onClickOption?.(option);
    setMenuIsOpen(false);
    onClose();
  };

  const handleClose = React.useCallback(() => {
    setMenuIsOpen(false);
    onClose();
  }, [menuIsOpen]);

  useClickAway(containerRef, !menuIsOpen ? handleClose : () => {});
  useClickAway(menuContentRef, menuIsOpen ? handleClose : () => {});

  const contentMenu = (
    <ul
      ref={menuContentRef}
      className={classNames(
        ' dark:bg-background-tetriary-dark',
        isMobile
          ? 'flex w-full flex-col gap-[12px] px-[16px] py-[16px]'
          : 'w-[192px] rounded-[5px] border-0 py-[8px] shadow-shadow-menu-dekstop'
      )}
    >
      <TooltipMenu.Item
        label={t('userProfileCard.shareTo', {
          to: '...',
        })}
        onClick={handleClickOption('share')}
        icon={<ArrowUturnRightIcon width={20} />}
      />

      <TooltipMenu.Item
        label={t('userProfileCard.copyProfileLink')}
        onClick={handleClickOption('copy-profile-link')}
        icon={<LinkIcon width={20} />}
      />

      <hr className="border-t-element-subtle dark:border-t-element-subtle-dark" />

      <TooltipMenu.Item
        label={t('userProfileCard.report')}
        onClick={handleClickOption('report')}
        icon={<FlagIcon width={20} />}
      />

      <TooltipMenu.Item
        label={t('userProfileCard.block')}
        onClick={handleClickOption('block')}
        icon={<NoSymbolIcon width={20} />}
        className="text-element-error"
      />
    </ul>
  );

  const handleOpenMenu = () => {
    if (isMobile) {
      userProfileContent = interactiveStore.content;
      interactiveStore.setContent(contentMenu);
      interactiveStore.setInteractiveElementOpen(true);
      return;
    }
    setMenuIsOpen(true);
  };

  const menuActionUI =
    ownProfile && isMobile ? (
      <div className="mb-[24px] flex w-full items-center justify-between gap-[8px] border-b border-b-element-subtle px-[16px] py-[8px] dark:border-element-subtle-dark">
        <CommunitySVG
          width={24}
          height={24}
          color={
            darkMode
              ? colorSchema['background-primary']
              : colorSchema['text-primary']
          }
        />

        <span className="text-body16SB">{t('userProfileCard.title')}</span>

        <Cog6ToothIcon
          className="pressed-el border-0 dark:text-text-secondary-dark"
          title={t('settings') as string}
          width={24}
          onClick={() => {
            onSettings?.();
            onClose();

            if (isMobile) {
              interactiveStore.setInteractiveElementOpen(false);
            }
          }}
        />
      </div>
    ) : (
      <div className="absolute right-[16px] top-[20px]">
        {ownProfile ? (
          <IconButton
            onClick={() => {
              onSettings?.();
              onClose();

              if (isMobile) {
                interactiveStore.setInteractiveElementOpen(false);
              }
            }}
            cn="p-[7px] themed-text-secondary border-0"
          >
            <Cog6ToothIcon
              className="themed-text-secondary hover-el-opacity animation-classic"
              title={t('settings') as string}
              width={18}
            />
          </IconButton>
        ) : (
          <TooltipMenu
            open={menuIsOpen}
            onClose={handleClose}
            contentStyle={{
              width: 'auto',
              padding: '0',
              fontSize: '14px',
              borderRadius: 5,
              borderColor: darkMode
                ? colorSchema['element-subtle-dark']
                : colorSchema['element-subtle'],
            }}
            closeOnDocumentClick={false}
            arrow={false}
            trigger={
              <div className="rotate-90" title={t('more') as string}>
                <DotsButton
                  className="text-text-secondary"
                  onClick={handleOpenMenu}
                />
              </div>
            }
            position={['bottom left']}
          >
            {contentMenu}
          </TooltipMenu>
        )}
      </div>
    );

  return (
    <div
      ref={containerRef}
      className={classNames(
        'relative flex w-full flex-col items-center bg-background-primary px-[24px] pb-[20px]  dark:bg-background-tetriary-dark md:w-[340px]',
        isMobile && ownProfile ? 'pb-[34px]' : 'max-h-[426px] pt-[24px]',
        rootCn
      )}
    >
      {!hideMenu && menuActionUI}

      <Avatar src={avatarUrl} width={120} height={120} darkMode={darkMode} />

      <div className="mt-[16px] flex flex-col text-center">
        <span className="text-subheadingSB text-text-primary dark:text-text-primary-dark">
          {displayName}
        </span>

        <span className="text-body16SB text-text-secondary">@{username}</span>
      </div>

      {bio && (
        <span className="mt-[20px] block w-full text-left text-body16R text-text-secondary dark:text-text-secondary-dark">
          {bio}
        </span>
      )}

      {website && (
        <div className="mt-[16px] flex w-full ">
          <LinkIcon className="text-primary-100" width={18} />

          <span className="ml-[4px] block text-body16SB text-primary-100 dark:text-primary-100-dark">
            {website}
          </span>
        </div>
      )}

      {!hideAction && (
        <div className="w-full">
          <OutlineButton
            cn="mt-[16px] border-element-subtle"
            onClick={() => {
              onCtaClick?.();

              if (!isMobile) {
                onClose();
              }
            }}
          >
            {ownProfile
              ? t('userProfileCard.editMyProfile')
              : t('userProfileCard.sendMessage')}
          </OutlineButton>
        </div>
      )}
    </div>
  );
};

export const UserProfileCard = observer(_UserProfileCard);
