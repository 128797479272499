import { observer } from 'mobx-react-lite';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { HeaderModal } from './HeaderModal';

type Props = {
  children: React.ReactNode;
  title: string;
  showBackBtn?: boolean;
};

export const _ScreenWrappper = (props: Props) => {
  const { children, title, showBackBtn } = props;
  const {
    profileStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.profileStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const finalShowBtn =
    typeof showBackBtn === 'undefined' ? isMobile : showBackBtn;

  return (
    <div className="themed-layout relative flex h-full w-full max-w-[480px] flex-col">
      <HeaderModal
        onBack={profileStore.tryMoveBack}
        title={title}
        onClose={profileStore.tryHide}
        showBackButton={finalShowBtn}
      />

      {children}
    </div>
  );
};

export const ScreenWrapper = observer(_ScreenWrappper);
