import { classNames } from '@foundationPathAlias/utilities';
import React, { forwardRef } from 'react';
export type SquareIconButtonBasePropsType = {
  className?: string;
  iconWrapperCn?: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  onPointerUp?: (e: React.MouseEvent) => void;
  disabled?: boolean;
};

export function _SquareIconButtonBase(
  props: SquareIconButtonBasePropsType,
  ref: React.Ref<HTMLButtonElement>
) {
  const {
    children,
    onClick,
    onPointerUp,
    className = '',
    iconWrapperCn,
    disabled,
    ...otherProps
  } = props;
  return (
    <button
      className={classNames(
        'square-icon-button-base',
        disabled ? 'opacity-40' : 'hover-el',
        className
      )}
      onClick={onClick}
      onPointerUp={onPointerUp}
      disabled={disabled}
      ref={ref}
      {...otherProps}
    >
      <figure
        className={classNames(
          'flex h-[24px] w-[24px] items-center justify-center',
          iconWrapperCn
        )}
      >
        {children}
      </figure>
    </button>
  );
}

export const SquareIconButtonBase = forwardRef(_SquareIconButtonBase);
