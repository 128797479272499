import { PlusIcon } from '@heroicons/react/20/solid';

import { classNames } from '@foundationPathAlias/utilities';
export type CirclePlusButtonPropsType = {
  cn?: string;
  onClick: (e?: any) => void;
  disabled?: boolean;
};

export function CirclePlusButton(props: CirclePlusButtonPropsType) {
  const { onClick, cn = '', disabled } = props;
  return (
    <button
      className={classNames(
        'btn-standard flex h-[22px] w-[22px] items-center justify-center rounded-full bg-buttonsAndIcons-fillColor-secondary-default',
        disabled && 'opacity-40',
        cn
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <PlusIcon className="btn-standard h-[17px] w-[17px] text-text-secondary" />
    </button>
  );
}
