import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';

import { TextInput, validators } from '@foundationPathAlias/components';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenIds } from '@mainApp/src/modules/profile/data/constants';

import { ActionButtons } from './ActionButtons';
import { ScreenWrapper } from './ScreenWrapper';

export function _Website() {
  const { userStore, profileStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.profileStore,
    IOC_TOKENS.userStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { t } = useTranslation(['common', 'channel']);

  const serverDataWebsite = userStore?.me?.serverData.website;

  const isDirty = serverDataWebsite !== userStore?.me?.website;

  const resetAction = useCallback(() => {
    userStore.setDataField('website', serverDataWebsite as string);
  }, []);

  useEffect(() => {
    const dirtyData = isDirty
      ? {
          initialVal: serverDataWebsite || '',
          currentVal: userStore?.me?.website || '',
          screenName: ScreenIds.WEBSITE,
          resetAction,
        }
      : null;

    profileStore.setDirtyScreenData(dirtyData);
  }, [isDirty]);

  return (
    <ScreenWrapper
      title={t<string>('channel:editProfileModal.editWebsite')}
      showBackBtn={true}
    >
      <div className="flex-1 px-[12px] py-[24px]">
        <label
          htmlFor="website"
          className="themed-text mb-[24px] block text-left text-body16R"
        >
          {t('channel:editProfileModal.addYourWebsite')}
        </label>
        <TextInput
          validators={[
            (value) => {
              if (!value) return null;
              const validator = validators.getWebsiteValidator(
                t('errors.invalidWebsiteUrl') as string
              );
              return validator(value);
            },
          ]}
          getCustomErrorUI={(error) => (
            <div className="themed-text-error text-body14R text-left">
              {error}
            </div>
          )}
          onChange={(val) => {
            userStore.setDataField('website', val);
          }}
          placeholder={t('channel:editProfileModal.addAWebsite') as string}
          InputProps={{
            id: 'website',
            defaultValue: serverDataWebsite || undefined,
          }}
        />
      </div>
      {isDirty && (
        <ActionButtons
          isMobile={dimensionsStore.isMobile}
          onClose={profileStore.tryMoveBack}
          onSubmit={userStore.updateCurrentUser}
          isSubmitting={userStore.isSubmitting}
          submitLabel={t('channel:editProfileModal.saveChanges')}
        />
      )}
    </ScreenWrapper>
  );
}

export const Website = withAnimatedStack(observer(_Website));
