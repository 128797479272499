import { useCallback, useState } from 'react';

// validator returns null if everything is ok or the error message
// TODO: remove any.
export type ValidatorType<T> = (val: T) => null | any; // string | JSX.ELement

function getMaxLengthValidator(
  maxCharCount: number,
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    let res: null | string = null;
    if (val.length > maxCharCount) {
      res =
        errorMessage || 'Max char length is exceeded. Allowed: ' + maxCharCount;
    }

    return res;
  };
}

function getEmptyFieldValidation(errorMessage?: string): ValidatorType<string> {
  return (val: string) => {
    let res: null | string = null;
    if (!val?.length) {
      res = errorMessage || 'This field cannot be empty.';
    }

    return res;
  };
}

function getAlreadyExistsOnListValidator(
  list: string[],
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    let res: null | string = null;
    if (
      val &&
      list
        .map((item) => item.toLocaleLowerCase())
        .includes(val.toLocaleLowerCase())
    ) {
      res = errorMessage || 'This value already exists.';
    }

    return res;
  };
}

function getEmailValidator(errorMessage?: string): ValidatorType<string> {
  return (val: string) => {
    const regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let res: null | string = null;
    if (!regexp.test(val)) {
      res = errorMessage || 'Invalid email format';
    }

    return res;
  };
}

function getNonPeriodOrUnderscoreStartValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /^[.|_].*/;
    let res: null | string = null;
    if (regexp.test(val)) {
      res =
        errorMessage || 'The field cannot start with a period or underscore.';
    }

    return res;
  };
}

function getNonPeriodOrUnderscoreEndValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /.*[.|_]$/;
    let res: null | string = null;
    if (regexp.test(val)) {
      res = errorMessage || 'The field cannot end with a period or underscore.';
    }

    return res;
  };
}

function getOnlyCharNumberPeriodUnderscoreValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /^[\w\n_.]+$/;
    let res: null | string = null;
    if (!regexp.test(val)) {
      res =
        errorMessage ||
        'Please use only letters, numbers, underscores, or periods.';
    }

    return res;
  };
}

function getBeginSpaceValidator(errorMessage?: string): ValidatorType<string> {
  return (val: string) => {
    const regexp = /^\s/;
    let res: null | string = null;
    if (regexp.test(val)) {
      res = errorMessage || 'The field cannot begin with space.';
    }

    return res;
  };
}

function getEndSpaceValidator(errorMessage?: string): ValidatorType<string> {
  return (val: string) => {
    const regexp = /\s$/;
    let res: null | string = null;
    if (regexp.test(val)) {
      res = errorMessage || 'The field cannot end with space.';
    }

    return res;
  };
}

function getDoubleConsequentSpacesValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /\s{2}/;
    let res: null | string = null;
    if (regexp.test(val)) {
      res = errorMessage || 'The field cannot contain two consequent spaces.';
    }

    return res;
  };
}

function getDoubleUnderscoreValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /^.*__.*$/;
    let res: null | string = null;
    if (regexp.test(val)) {
      res =
        errorMessage ||
        'There cannot be more than one consecutive period or underscore in it.';
    }

    return res;
  };
}

function getPeriodAfterUnderscoreValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /[^_]_\./;
    let res: null | string = null;
    if (regexp.test(val)) {
      res = errorMessage || 'A period cannot come after an underscore.';
    }

    return res;
  };
}

function getUnderscoreAfterPeriodValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /\._/;
    let res: null | string = null;
    if (regexp.test(val)) {
      res = errorMessage || 'An underscore cannot come after a period.';
    }

    return res;
  };
}

function get10XValidator(errorMessage?: string): ValidatorType<string> {
  return (val: string) => {
    const regexp = /10[xXхХхХ]/i;
    let res: null | string = null;
    if (regexp.test(val)) {
      res =
        errorMessage ||
        'The field cannot contain the following substrings: 10x';
    }

    return res;
  };
}

function getEveryoneHereValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /everyone|here/i;
    let res: null | string = null;
    if (regexp.test(val)) {
      res = errorMessage || 'The field cannot be: everyone, here';
    }

    return res;
  };
}
function getSpecialSymbolsAndDoubleConsequentSpaceValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /^[\w]+([- ][\w]+)*/i;
    let res: null | string = null;
    if (regexp.test(val)) {
      res =
        errorMessage ||
        'The field cannnot contain special symbols or double consequent spaces';
    }

    return res;
  };
}
/**
 * validates if the string contains special symbols except a single space and dash
 */
function getSpecialSymbolsNonSpaceOrDashValidator(
  errorMessage?: string
): ValidatorType<string> {
  return (val: string) => {
    const regexp = /[^\w\s-]/i;
    let res: null | string = null;
    if (regexp.test(val)) {
      res =
        errorMessage ||
        'The field cannot contain special symbols except a single space or dash';
    }

    return res;
  };
}
/**
 * validates if the string is a valid website URL
 */
function getWebsiteValidator(errorMessage?: string): ValidatorType<string> {
  return (val: string) => {
    const regexp =
      /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/i;

    let res: null | string = null;
    if (!regexp.test(val)) {
      res = errorMessage || 'The field must be a valid website URL';
    }

    return res;
  };
}

export function useValidators<T>(validators: ValidatorType<T>[]) {
  const [error, setError] = useState<null | string>(null);
  const resetValidationError = useCallback(() => {
    setError('');
  }, []);

  const runValidators = useCallback((value: T) => {
    let res = null;
    for (let i = 0; i < validators.length; i++) {
      const validator = validators[i];
      const errorMessage = validator(value);
      if (errorMessage) {
        res = errorMessage;
        break;
      }
    }

    setError(res);
    return res;
  }, []);

  return {
    error,
    resetValidationError,
    runValidators,
  };
}

export const validators = {
  getMaxLengthValidator,
  getEmailValidator,
  getEmptyFieldValidation,
  getBeginSpaceValidator,
  getEndSpaceValidator,
  getAlreadyExistsOnListValidator,
  getSpecialSymbolsNonSpaceOrDashValidator,
  getSpecialSymbolsAndDoubleConsequentSpaceValidator,
  getDoubleConsequentSpacesValidator,
  getNonPeriodOrUnderscoreStartValidator,
  getNonPeriodOrUnderscoreEndValidator,
  getOnlyCharNumberPeriodUnderscoreValidator,
  getDoubleUnderscoreValidator,
  getPeriodAfterUnderscoreValidator,
  getUnderscoreAfterPeriodValidator,
  get10XValidator,
  getEveryoneHereValidator,
  getWebsiteValidator,
};
