import { classNames } from '@foundationPathAlias/utilities';

type Props = {
  className?: string;
};

export const Separator = ({ className }: Props) => (
  <hr
    className={classNames(
      'my-[4px] h-[1px] w-full border-element-subtle dark:border-element-subtle-dark',
      className
    )}
  />
);
