import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { CameraIcon } from '@heroicons/react/24/outline';

import { Avatar, IconButton } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

export type EditUserAvatarPropsType = {
  cn?: string;
  onClick: () => void;
};

export function _EditUserAvatar(props: EditUserAvatarPropsType) {
  const { onClick, cn } = props;

  const { t } = useTranslation('common');

  const { systemStore, userStore } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.userStore,
  ]);

  if (!userStore?.me?.serverData) {
    return null;
  }
  const { username, displayName } = userStore.me.serverData;

  return (
    <div className={classNames('flex flex-col self-center', cn)}>
      <div className="relative flex flex-col items-center justify-center ">
        <Avatar
          darkMode={systemStore.isDarkTheme}
          width={120}
          height={120}
          src={userStore.me?.avatarUrl}
          alt={t<string>('avatarImage', {
            name: userStore.me?.serverData?.displayName,
          })}
        />

        <IconButton
          cn="absolute p-[10px] self-center w-fit border-0 bg-background-overlay"
          onClick={onClick}
        >
          <CameraIcon
            width={24}
            height={24}
            className="text-text-primary-dark"
          />
        </IconButton>
      </div>

      <b className="themed-text mt-[16px] text-center text-subheadingSB">
        {displayName}
      </b>
      <span className="themed-text-secondary text-body16R">@{username}</span>
    </div>
  );
}

export const EditUserAvatar = observer(_EditUserAvatar);
