import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenToggleItem } from '@mainApp/src/modules/account-settings/common';

import { EditUserAvatar, ScreenWrapper, UserCropImage } from './components';
import { ScreenIds } from './data/constants';

export function _EditProfile() {
  const { profileStore, interactiveStore, userStore, accountSettingsStore } =
    useMultipleInjection([
      IOC_TOKENS.profileStore,
      IOC_TOKENS.interactiveStore,
      IOC_TOKENS.userStore,
      IOC_TOKENS.accountSettingsStore,
    ]);

  const { t } = useTranslation(['common', 'channel']);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const userData = userStore?.me?.serverData;
  return (
    <ScreenWrapper
      title={t('channel:editProfileModal.editProfile')}
      showBackBtn={false}
    >
      <input
        type="file"
        accept="image/*"
        ref={inputFileRef}
        hidden
        onChange={(e) => {
          const file = e?.target?.files && e?.target?.files[0];

          if (file) {
            interactiveStore.setDisableBottomSheetTouchMove(true);
            interactiveStore.setContent(
              <UserCropImage
                file={file}
                onCropDone={() => {
                  interactiveStore.setDisableBottomSheetTouchMove(false);
                  interactiveStore.setContent(null);
                  interactiveStore.setInteractiveElementOpen(false);
                  profileStore.show();
                }}
                onBackOrCancel={() => {
                  interactiveStore.setDisableBottomSheetTouchMove(false);
                  interactiveStore.setContent(null);
                  interactiveStore.setInteractiveElementOpen(false);
                  profileStore.show();
                }}
              />
            );

            interactiveStore.setInteractiveElementOpen(true);
            profileStore.hide();
          }
        }}
      />
      <div className="flex flex-col px-[16px]  py-[24px] lg:px-[24px]">
        <EditUserAvatar
          cn="mb-[20px]"
          onClick={() => {
            inputFileRef.current?.click();
          }}
        />

        <ScreenToggleItem
          btnCn="my-0 mb-[4px]"
          label={t('name')}
          value={userData?.displayName || ''}
          onClick={() => {
            profileStore.moveToScreen(ScreenIds.NAME);
          }}
        />

        <ScreenToggleItem
          btnCn="my-0 mb-[4px]"
          label={t('username')}
          value={'@' + (userData?.username || '')}
          showGetPro={!userStore?.me?.isActivePro}
          onClick={() => {
            profileStore.hide();
            accountSettingsStore.setShow(true);
          }}
        />

        <ScreenToggleItem
          btnCn="my-0 mb-[4px]"
          label={t('channel:editProfileModal.bio')}
          value={t<string>('channel:editProfileModal.describeYourself')}
          onClick={() => {
            profileStore.moveToScreen(ScreenIds.BIO);
          }}
        />

        <ScreenToggleItem
          btnCn="my-0"
          label="Website"
          value={
            userData?.website ||
            t<string>('channel:editProfileModal.addYourWebsite')
          }
          onClick={() => {
            profileStore.moveToScreen(ScreenIds.WEBSITE);
          }}
        />
      </div>
    </ScreenWrapper>
  );
}

export const EditProfile = withAnimatedStack(observer(_EditProfile));
