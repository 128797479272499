import { useContext } from 'react';

import { InversifyContext } from './IOCProvider';

import IOC_TOKENS, { IOC_TOKEN_TYPE } from './ioc-tokens';

export function useInjection<K extends keyof typeof IOC_TOKENS>(
  identifier: K
): IOC_TOKEN_TYPE[K] {
  const { container } = useContext(InversifyContext);

  if (!container) {
    throw new Error();
  }
  const res = container.get(identifier);
  return res;
}

/**
 * Injects multiple dependencies
 * @param { string[] } tokensArray - array of keys for injected dependenkies. Resulted object will contin only them
 * @returns { [K in T[number]]: TokensTypeObject[K] } - composed object of injected dependecies containing dependencies which keys were presented in the tokesArray
 */
export function useMultipleInjection<T extends (keyof typeof IOC_TOKENS)[]>(
  tokensArray: T
): {
  [K in T[number]]: IOC_TOKEN_TYPE[K];
} {
  const { container } = useContext(InversifyContext);

  if (!container) {
    throw new Error();
  }

  const res = {} as {
    [K in T[number]]: IOC_TOKEN_TYPE[K];
  };

  tokensArray.forEach((key) => {
    const item = container.get<typeof key>(key);
    // @ts-ignore
    res[key] = item;
  });

  return res;
}
