import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastTypesEnum } from '@foundationPathAlias/components';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

export function useCopyCurrentUrlClipboard() {
  const toastStore = useInjection(IOC_TOKENS.toastStore);

  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  const { t } = useTranslation('common');

  const copyCurrentUrlToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(currentUrl);
      toastStore.show({
        type: ToastTypesEnum.SUCCESS,
        title: t('urlCopiedToClipboard'),
        content: t('youCanShareIt'),
      });
    } catch (err) {
      toastStore.show({
        type: ToastTypesEnum.ERROR,
        title: t('errors.error'),
        content: t('errors.failedToCopyURLToClipboard'),
      });
    }
  }, []);

  return copyCurrentUrlToClipboard;
}
