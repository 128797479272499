import { AnimatedStack } from '@foundationPathAlias/main';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { ScreenIds } from './constants';
import { screensConfig } from './screensConfig';
import { DirtyScreendData, IProfileStore } from './types';

@injectable()
export class ProfileStore implements IProfileStore {
  animatedStack: AnimatedStack | null = null;

  active = false;

  discardAlertIsActive = false;
  dirtyScreenData: DirtyScreendData = null;

  get isStackHistoryExisting() {
    return Boolean(this.animatedStack?.state.history.length);
  }

  constructor() {
    makeObservable(this, {
      active: observable,
      discardAlertIsActive: observable,
      dirtyScreenData: observable,

      show: action,
      hide: action,
      tryHide: action,
      tryMoveBack: action,
      setDirtyScreenData: action,
      setDiscardAlertIsActive: action,
    });
  }

  setDirtyScreenData = (dirtyScreenData: DirtyScreendData | null) => {
    this.dirtyScreenData = dirtyScreenData;
  };

  setAnimatedStack = (animatedStack: AnimatedStack) => {
    this.animatedStack = animatedStack;
  };

  show = () => {
    if (this.active) return;

    this.active = true;
    this.initAnimatedStack();
  };

  hide = () => {
    this.active = false;
  };

  moveBack = () => {
    this.animatedStack?.back();
  };

  moveToScreen = (id: (typeof ScreenIds)[keyof typeof ScreenIds]) => {
    const screen = screensConfig[id];
    this.animatedStack?.next(screen.id, screen.Component);
  };

  initAnimatedStack = () => {
    // set initial stack item
    const { id, Component } = screensConfig[ScreenIds.INITIAL];
    this.animatedStack?.setVisible(id, Component);
  };

  tryMoveBack = () => {
    if (this.dirtyScreenData) {
      const outerResetAction = this.dirtyScreenData.resetAction;
      this.dirtyScreenData = {
        ...this.dirtyScreenData,
        resetAction: () => {
          outerResetAction?.();
          this.setDirtyScreenData(null);
          this.setDiscardAlertIsActive(false);
          this.moveBack();
        },
      };
      this.setDiscardAlertIsActive(true);
    } else {
      this.moveBack();
    }
  };

  tryHide = () => {
    if (this.dirtyScreenData) {
      const outerResetAction = this.dirtyScreenData.resetAction;
      this.dirtyScreenData = {
        ...this.dirtyScreenData,
        resetAction: () => {
          outerResetAction?.();
          this.setDirtyScreenData(null);
          this.setDiscardAlertIsActive(false);
          this.hide();
        },
      };
      this.setDiscardAlertIsActive(true);
    } else {
      this.hide();
    }
  };

  setDiscardAlertIsActive = (bool: boolean) => {
    this.discardAlertIsActive = bool;
  };
}
