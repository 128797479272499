import {
  SquareIconButtonBase,
  SquareIconButtonBasePropsType,
} from '@foundationPathAlias/main';
import { classNames } from '@foundationPathAlias/utilities';
import { BaseProps } from './types';

export type SquareIconButtonBaseMediumPropsType = BaseProps &
  Omit<SquareIconButtonBasePropsType, 'children'>;

type Props = {
  Icon: React.ComponentType<{ className?: string }>;
} & SquareIconButtonBaseMediumPropsType;

export const SquareIconButtonBaseMedium = ({
  className,
  cnIcon,
  Icon,
  ...props
}: Props) => {
  return (
    <SquareIconButtonBase
      className={classNames('flex h-[32px] w-[32px]', className)}
      {...props}
    >
      <Icon className={classNames('h-[24px] w-[24px]', cnIcon)} />
    </SquareIconButtonBase>
  );
};
