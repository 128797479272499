'use client';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import {
  TextInputCommonValidators,
  TextInputCommonValidatorsLabel,
} from '@10x/foundation/src/components/inputs';
import { ChangesAlert, Toggle } from '@foundationPathAlias/components';
import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';
import { SmallLoader } from '@foundationPathAlias/components/loaders';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { ScreenIds } from './data/screensConfig';
import { FooterModal } from './FooterModal';
import { HeaderModal } from './HeaderModal';

export const _CreateChannelOrGroupForm = () => {
  const { t } = useTranslation(['common', 'channel']);

  const [checking, setChecking] = useState(false);

  const {
    dimensionsStore: { isMobile },
    createChannelOrGroupStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.createChannelOrGroupStore,
  ]);

  const {
    isChannelNameAvailable,
    isChannelGroupNameAvailable,
    isGroupMode,
    channelName,
    channelGroupName,
    isPrivate,
    isSubmitting,
    requestCloseModal,
    setChannelName,
    setChannelGroupName,
    setIsPrivate,
    submitCreateChannel,
    submitCreateChannelGroup,
    moveToScreen,
  } = createChannelOrGroupStore;

  let submitAction = submitCreateChannel;
  let checkNameAction = isChannelNameAvailable;
  let setNameAction = setChannelName;
  let nameField = channelName;
  let errorMsg = t('channel:modal.channelAlreadyExists');
  let visibilityLabel = t('channel:modal.channelVisibility');
  let privateExplanation = t('channel:modal.onlySelectedRolesAndMembers');
  let nextText = t<string>('channel:modal.createChannel');
  let title = t<string>('channel:modal.createChannel');

  let label = t('channel:modal.channelName');
  let placeholder = t('channel:modal.writeNameForYourChannel');
  if (isGroupMode) {
    submitAction = submitCreateChannelGroup;
    checkNameAction = isChannelGroupNameAvailable;
    setNameAction = setChannelGroupName;
    nameField = channelGroupName;
    title = t<string>('channel:modal.createChannelGroup');
    label = t('channel:modal.channeGroupName');
    errorMsg = t('channel:modal.groupAlreadyExists');
    placeholder = t('channel:modal.writeNameForYourChannelGroup');
    visibilityLabel = t('channel:modal.channelGroupVisibility');
    privateExplanation = t('channel:modal.onlySelectedRolesAndMembersGroup');
    nextText = t<string>('channel:modal.createChannelGroup');
  }

  useDebounce(
    async () => {
      const isAvailable = await checkNameAction();

      setNameAction({
        ...nameField,
        error: isAvailable ? null : errorMsg,
      });

      setChecking(false);
    },
    300,
    [nameField.value]
  );

  const handleSubmit = () => {
    if (isPrivate) {
      moveToScreen(ScreenIds.ADD_MEMBERS);
    } else {
      submitAction();
    }
  };

  return (
    <div className="themed-layout relative flex flex-1 flex-col overflow-hidden rounded-[5px] md:block md:flex-none">
      <HeaderModal
        onBack={() => null}
        title={title}
        onClose={createChannelOrGroupStore.requestCloseModal}
        showBackButton={false}
      />
      <div className="flex flex-1 flex-col">
        <div className="px-[16px] py-[24px] text-start md:p-[24px]">
          <TextInputCommonValidatorsLabel cn="mb-[16px]">
            {label}
          </TextInputCommonValidatorsLabel>
          <TextInputCommonValidators
            rootCn="mt-0"
            inputName="name"
            allowEmptyValue={true}
            onChange={(val) => {
              setChecking(true);
              setNameAction({
                value: val,
                error: nameField.error,
              });
            }}
            errorTextCn="text-element-error dark:text-element-error-dark"
            onError={(error) => {
              setNameAction({
                value: nameField.value,
                error: error,
              });
            }}
            rightSideContent={checking ? <SmallLoader /> : undefined}
            outerError={nameField.error}
            value={nameField.value}
            // label={label}
            placeholder={placeholder}
          />

          <hr className="my-6" />

          <label className="text-body16SB text-text-primary dark:text-text-primary-dark">
            {visibilityLabel}
          </label>

          <div className="my-4 flex items-center justify-between gap-12">
            <div>
              <p className="text-sm14SB text-text-primary dark:text-text-primary-dark">
                {t('channel:modal.makePrivate')}
              </p>

              <span className="text-sm14R text-text-secondary dark:text-text-secondary-dark">
                {privateExplanation}
              </span>
            </div>

            <Toggle checked={isPrivate} onChange={setIsPrivate} />
          </div>
        </div>

        <FooterModal
          onCancel={requestCloseModal}
          onSuccess={handleSubmit}
          loading={isSubmitting}
          disabledOkButton={
            isSubmitting ||
            checking ||
            Boolean(nameField.error) ||
            !nameField.value
          }
          successText={isPrivate ? t<string>('common:next') : nextText}
          hideCancelButton={isMobile}
          className="mt-auto md:mt-0"
        />
      </div>
      <ChangesAlert
        contentWrapperCn="border-[1px]"
        title={t('channel:modal.discardChangesTitle')}
        description={t('channel:modal.discardChangesDescription')}
        descriptionCn="text-left"
        show={createChannelOrGroupStore.discardAlertIsActive}
        onFirstBtnClick={() => {
          createChannelOrGroupStore.setDiscardAlertIsActive(false);
        }}
        onSecondBtnClick={createChannelOrGroupStore.hide}
        firstBtnText={t<string>('channel:modal.keepEditing')}
        secondBtnText={t<string>('channel:modal.closeAndDiscard')}
      />
    </div>
  );
};

export const CreateChannelOrGroupForm = withAnimatedStack(
  observer(_CreateChannelOrGroupForm)
);
