import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import type { IDimensionsStore } from './types';
import { BottomSheetActions } from './types';

import { inject, injectable } from 'inversify';
import { IOC_TOKENS } from '../ioc';
import type { IInteractiveStore } from './types/Interactive.store.types';
import { InteractiveContentTypesEnum } from './types/Interactive.store.types';
export * from './types/Interactive.store.types';

enableStaticRendering(typeof window === 'undefined');

const activeContentType = {
  auth: false,
  onboarding: false,
  other: false,
};

@injectable()
export class InteractiveStore implements IInteractiveStore {
  dimensionsStore: IDimensionsStore;

  disableBottomSheetTouchMove = false;

  bottomSheetActions: BottomSheetActions;
  isBottomSheetOpened = false;
  bottomSheetCn? = '';

  isModalOpened = false;

  private reactionDisposer: any;

  content: React.ReactNode = null;

  // bottomsheet or modal
  get isOpened() {
    return this.isBottomSheetOpened || this.isModalOpened;
  }

  get isBottomSheetActionsExists() {
    const { open, close } = this.bottomSheetActions;
    if (!open) {
      console.error('Bottomsheet `open` function does not exists');
    }

    if (!close) {
      console.error('Bottomsheet `close` function does not exists');
    }

    return Boolean(close) && Boolean(open);
  }

  /**
   * basically this prop will be true in the case when some code request to open
   * the bottomsheet but the bottomsheet actions aren't ready. So in the
   * setBottomSheetActions method should be checked it and triggered opening if needed
   */
  private shouldOpenBottomSheetWhenPossible = false;

  // TODO: Experimenal
  activeContentTypeRegistry = { ...activeContentType };

  constructor(
    @inject(IOC_TOKENS.dimensionsStore) dimensionsStore: IDimensionsStore
  ) {
    this.dimensionsStore = dimensionsStore;

    makeObservable(this, {
      isBottomSheetOpened: observable,
      bottomSheetCn: observable,
      activeContentTypeRegistry: observable,
      isModalOpened: observable,

      disableBottomSheetTouchMove: observable,

      isOpened: computed,
      content: observable,
      setActiveContentType: action,
      setDisableBottomSheetTouchMove: action,
      setInteractiveElementOpen: action,
      setContent: action,
      resetContent: action,
      setBottomSheet: action,
    });

    this.bottomSheetActions = {
      open: null,
      close: null,
    };

    this.reactionDisposer = reaction(
      () => {
        const isMobile = dimensionsStore.isMobile;
        const { isBottomSheetOpened, isModalOpened } = this;

        return {
          isMobile,
          isBottomSheetOpened,
          isModalOpened,
        };
      },
      ({ isMobile, isModalOpened, isBottomSheetOpened }) => {
        const { setIsModalOpened, setIsBottomSheetOpened, bottomSheetActions } =
          this;
        if (isMobile) {
          // screen resize, we on mobile but the auth modal is opened
          if (!isBottomSheetOpened && isModalOpened) {
            setIsModalOpened(false);
            setIsBottomSheetOpened(true);
            bottomSheetActions.open?.();
          }
        } else {
          // vice versa. Resizing from small to wide screen with the opened
          // bottomsheet. Need to close it and open the modal
          if (isBottomSheetOpened && !isModalOpened) {
            setIsModalOpened(true);
            setIsBottomSheetOpened(false);
            bottomSheetActions.close?.(4.5);
          }
        }
      }
    );
  }

  setDisableBottomSheetTouchMove = (disable: boolean) => {
    this.disableBottomSheetTouchMove = disable;
  };

  setActiveContentType = (
    type: InteractiveContentTypesEnum,
    active: boolean
  ) => {
    // if one type of content active - other should be inactive

    this.activeContentTypeRegistry = {
      ...activeContentType,
      [type]: active,
    };
  };

  /* TOOD:: I don't like this implementation. Would be nice to have
    a single method to close and reset the content
    hovewer the bottomsheet onClose shouldn't trigger the
    close method as it will trigger infinity loop.
    TODO: Maybe closeMethod with a marker to avoid close?
    thus the bottomsheet could set it to false and just reset the content
  */
  resetContent = () => {
    this.activeContentTypeRegistry = { ...activeContentType };
    this.content = null;
  };

  setContent = (content: React.ReactNode) => {
    this.content = content;
  };

  setInteractiveElementOpen = (open: boolean) => {
    const isMobile = this.dimensionsStore.isMobile;
    const finalAction = isMobile ? this.setBottomSheet : this.setModal;

    finalAction(open);
  };
  setBottomSheetActions = (actions: any) => {
    this.bottomSheetActions = actions;
    if (this.shouldOpenBottomSheetWhenPossible) {
      // don't have a dragEvent but want to reset the marker on end
      actions.open(undefined, () => {
        this.shouldOpenBottomSheetWhenPossible = false;
      });
    }
  };

  setIsModalOpened = (open: boolean) => {
    this.isModalOpened = open;
  };
  setIsBottomSheetOpened = (open: boolean) => {
    this.isBottomSheetOpened = open;
  };

  setModal = (open: boolean) => {
    this.isBottomSheetOpened = false;
    this.isModalOpened = open;

    // TODO: TEST
    if (!open) {
      this.setContent(null);
    }
  };

  setBottomSheetCn = (bottomSheetCn?: string) => {
    this.bottomSheetCn = bottomSheetCn;
  };

  setBottomSheet = (open: boolean) => {
    this.isBottomSheetOpened = open;
    this.isModalOpened = false;

    if (!this.isBottomSheetActionsExists) {
      this.shouldOpenBottomSheetWhenPossible = true;
      return;
    }

    let finalAction = this.bottomSheetActions.open;

    if (!open) {
      // basically there should be an erro if it does not exists
      // @ts-ignore
      finalAction = this.bottomSheetActions.close.bind(4.5);
      this.setContent(null);
    }
    // TODO: Refactor it?
    finalAction?.();

    return null;
  };

  dispose = () => {
    this.reactionDisposer();
  };
}
