import { Bio, Name, Website } from '../components';
import { EditProfile } from '../EditProfile';
import { ScreenIds } from './constants';
export type ScreenIdsValuesType = (typeof ScreenIds)[keyof typeof ScreenIds];

export const screensConfig = {
  [ScreenIds.INITIAL]: {
    id: ScreenIds.INITIAL,
    title: 'profile.edit',
    Component: EditProfile,
  },
  [ScreenIds.NAME]: {
    id: ScreenIds.NAME,
    title: 'profile.name',
    Component: Name,
  },
  [ScreenIds.BIO]: {
    id: ScreenIds.BIO,
    title: 'profile.bio',
    Component: Bio,
  },
  [ScreenIds.WEBSITE]: {
    id: ScreenIds.WEBSITE,
    title: 'profile.website',
    Component: Website,
  },
};
