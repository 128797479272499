import { ButtonBase } from '@foundationPathAlias/components/buttons/ButtonBase';
import { classNames } from '@foundationPathAlias/utilities';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

export type Props = {
  label: string;
  value?: string | JSX.Element;
  onClick: () => void;
  showGetPro?: boolean;
  btnCn?: string;
};
export function ScreenToggleItem(props: Props) {
  const { label, value, onClick, btnCn, showGetPro } = props;
  const { t } = useTranslation(['channel']);

  return (
    <div className="-mx-[16px]">
      <ButtonBase
        cn={classNames(
          'flex w-full px-[16px] py-[8px] items-center justify-between mt-[12px] mb-[12px] hover:bg-hovered-selected dark:hover:bg-hovered-selected-dark active:bg-pressed dark:active:bg-pressed-dark',
          btnCn
        )}
        onClick={onClick}
      >
        <p className="flex flex-col justify-center space-y-[8px]">
          <p className="themed-text flex items-center text-left text-body16R">
            {label}
            {showGetPro && (
              <p className="themed-primary-bg-color-dynamic text-text-sm14T ml-[16px] flex h-[20px] items-center rounded-[10px] px-[8px] pb-[2px] text-text-primary-dark">
                {t('editProfileModal.getPro')}
              </p>
            )}
          </p>
          {value && (
            <span className="themed-text-secondary text-left text-body16R">
              {value}
            </span>
          )}
        </p>
        <p>
          <ChevronRightIcon className="themed-text-secondary ml-[16px] h-[28px] w-[28px]" />
        </p>
      </ButtonBase>
    </div>
  );
}
