import { Separator } from '@foundationPathAlias/components/common';
import {
  ModalHeader,
  ModalHeaderPropsType,
} from '@mainApp/src/components/common/ModalHeader';

import { ActionsPanelData } from '@foundationPathAlias/widgets/sidebar-modal';
import { ActionsPanel, ActionsPanelPropsType } from './ActionsPanel';

export type DeleteModalContentPropsType = {
  actionsPanelData: ActionsPanelData;
  title: string;
  children: React.ReactNode;
  onClose: () => void;
  cn?: string;
  titleCn?: string;
  ModalHeaderProps?: Partial<ModalHeaderPropsType>;
  ActionsPanelProps?: Partial<ActionsPanelPropsType>;
};

export function DeleteModalContent(props: DeleteModalContentPropsType) {
  const {
    title,
    ModalHeaderProps = {},
    children,
    onClose,
    actionsPanelData,
    ActionsPanelProps = {},
  } = props;

  return (
    <div className="themed-layout min-h-[224px] w-full flex-1 rounded-[10px] pb-[16px] md:w-[560px] md:pb-0">
      <ModalHeader onClose={onClose} {...ModalHeaderProps}>
        {title}
      </ModalHeader>
      <Separator cn="my-0" />
      <div className="min-h-fit flex-1 px-[24px]">{children}</div>

      <ActionsPanel
        showActionPanel={true}
        noProceedButtonDynamicColor={true}
        rootCn="pb-[24px]"
        separatorCn="hidden"
        cancelBtnCn="flex-1 md:flex-0"
        proceedBtnCn="themed-layout-error active:bg-element-error dark:active:bg-element-error-dark hover-el pressed-el"
        actionsPanelData={actionsPanelData}
        {...ActionsPanelProps}
      />
    </div>
  );
}
