import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { withAnimatedStack } from '@foundationPathAlias/components/animated-stack';

import { TextInput } from '@foundationPathAlias/components';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ScreenIds } from '@mainApp/src/modules/profile/data/constants';

import { ActionButtons } from './ActionButtons';
import { ScreenWrapper } from './ScreenWrapper';

export function _Bio() {
  const { userStore, profileStore, dimensionsStore } = useMultipleInjection([
    IOC_TOKENS.profileStore,
    IOC_TOKENS.userStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const { t } = useTranslation(['channel'], { keyPrefix: 'editProfileModal' });

  const serverBio = userStore?.me?.serverData?.bio;
  const isDirty = serverBio !== userStore?.me?.bio;

  const resetAction = useCallback(() => {
    userStore.setDataField('bio', serverBio as string);
  }, []);

  useEffect(() => {
    const dirtyData = isDirty
      ? {
          initialVal: serverBio || '',
          currentVal: userStore?.me?.bio || '',
          screenName: ScreenIds.BIO,
          resetAction,
        }
      : null;

    profileStore.setDirtyScreenData(dirtyData);
  }, [isDirty]);

  return (
    <ScreenWrapper title={t<string>('editBio')} showBackBtn={true}>
      <div className="flex-1 px-[12px] py-[24px]">
        <label
          htmlFor="bio"
          className="themed-text mb-[24px] block text-left text-body16R"
        >
          {t('tellUsAboutYourself')}
        </label>
        <TextInput
          onChange={(val) => {
            userStore.setDataField('bio', val);
          }}
          TextAreaProps={{
            id: 'bio',
            rows: 4,
            defaultValue: serverBio || undefined,
          }}
          maxLength={80}
          textAreaMode={true}
        />
      </div>
      {isDirty && (
        <ActionButtons
          isMobile={dimensionsStore.isMobile}
          onClose={profileStore.tryMoveBack}
          onSubmit={userStore.updateCurrentUser}
          isSubmitting={userStore.isSubmitting}
          submitLabel={t('saveChanges')}
        />
      )}
    </ScreenWrapper>
  );
}

export const Bio = withAnimatedStack(observer(_Bio));
