import { observer } from 'mobx-react-lite';

import {
  ArrowSmallLeftIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline';
import { CloseIconBtn } from '@mainApp/src/components/common/icons';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

type HeaderButtonType = 'close' | 'arrow' | 'chevron';

type HeaderModalProps = {
  label: string;
  onCancel: () => void;
  buttonType?: HeaderButtonType;
};

const ICONS: {
  [key in HeaderButtonType]: React.ExoticComponent<{
    className: string;
    onClick: () => void;
  }>;
} = {
  close: CloseIconBtn as React.ExoticComponent,
  arrow: ArrowSmallLeftIcon,
  chevron: ChevronLeftIcon,
};

const _CropperHeaderModal = ({
  label,
  onCancel,
  buttonType = 'close',
}: HeaderModalProps) => {
  const { isMobile } = useInjection(IOC_TOKENS.dimensionsStore);

  const Icon = isMobile ? ICONS[buttonType] : ICONS.close;

  return (
    <div className="relative flex w-full justify-center border-b border-b-element-subtle px-4 py-4 dark:border-b-element-subtle-dark md:justify-between md:px-6 md:py-5">
      <span className="text-body16SB text-text-primary dark:text-text-primary-dark md:justify-self-auto md:text-subheadingSB">
        {label}
      </span>

      <Icon
        className="absolute left-4 h-6 w-6 md:relative md:left-0 md:justify-self-auto"
        onClick={onCancel}
      />
    </div>
  );
};

export const CropperHeaderModal = observer(_CropperHeaderModal);
